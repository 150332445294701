import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'

const IndexPage = props => {
	const allPages = props.data.allSitePage.edges.map(({ node }) => node.path)
	const filteredPages = allPages.filter(
		page =>
			page !== '/dev-404-page/' &&
			page !== '/offline-plugin-app-shell-fallback/' &&
			page !== '/404/' &&
			page !== '/' &&
			page !== '/404.html' &&
			page !== '/privacy-policy/' &&
			page !== '/terms/'
	)
	const sortedPages = filteredPages.sort()
	return (
		<Layout>
			<div className="container">
				<div className="content" style={{ margin: '2rem 0' }}>
					<h1 className="title">MyDolphin Stores Site Index</h1>
					{sortedPages.length > 0 && (
						<div>
							<h2 className="subtitle">
								If you were trying to reach an elite dealer page, perhaps you
								can find it below.
							</h2>
							<h3>MyDolphin Mini-Sites ({sortedPages.length})</h3>
							<ul>
								{sortedPages.map(page => (
									<li key={page}>
										<Link to={page}>{page}</Link>
									</li>
								))}
							</ul>
						</div>
					)}
					{/*
						// TODO Remove Netlify Form
					*/}
					<form
						name="free-demo"
						method="post"
						data-netlify="true"
						data-netlify-honeypot="bot-field"
						style={{
							position: 'absolute',
							height: 0,
							top: 0,
							left: 0,
							transform: 'translatex(-100%)',
							overflow: 'hidden',
						}}
					>
						<input type="hidden" name="form-name" value="free-demo" />
						<input type="text" name="name" />
						<br />
						<input type="email" name="email" />
						<br />
						<input type="tel" name="phone" />
						<br />
						<input type="text" name="region" />
						<br />
						<input type="text" name="model" />
						<br />
						<input type="text" name="pool_condition" />
						<br />
						<input type="text" name="pool_size" />
						<br />
						<input type="text" name="pool_surface" />
						<br />
						<input type="text" name="shop_name" />
						<br />
						<input type="email" name="shop_email" />
						<br />
						<button type="submit">Submit</button>
					</form>
					<form
						name="more-info"
						method="post"
						data-netlify="true"
						data-netlify-honeypot="bot-field"
						style={{
							position: 'absolute',
							height: 0,
							top: 0,
							left: 0,
							transform: 'translatex(-100%)',
							overflow: 'hidden',
						}}
					>
						<input type="hidden" name="form-name" value="more-info" />
						<input type="text" name="name" />
						<br />
						<input type="email" name="email" />
						<br />
						<input type="tel" name="phone" />
						<br />
						<input type="text" name="region" />
						<br />
						<textarea name="message" />
						<br />
						<input type="text" name="shop_name" />
						<br />
						<input type="email" name="shop_email" />
						<br />
						<button type="submit">Submit</button>
					</form>
				</div>
			</div>
		</Layout>
	)
}

IndexPage.propTypes = {
	data: PropTypes.any,
}

export default IndexPage

export const PageListQuery = graphql`
	query pageListQuery {
		allSitePage {
			edges {
				node {
					path
				}
			}
		}
	}
`
